import React from "react";
import cloud from "../images/expertise/cloud.png";
import UXUI from "../images/expertise/UX-UI.png";
import architecture from "../images/expertise/architecture.png";
import devops from "../images/expertise/devops.png";
import highQualitySoftware from "../images/expertise/highQualitySoftware.png";
import mobile from "../images/expertise/mobile.png";
import testing from "../images/expertise/testing.png";
import arrowLeft from "../images/leftArrow.svg";
import arrowRight from "../images/rightArrow.svg";
import SliderContainer from "./Slide";

const expertiseList = [
    {   key:1,
        title: "High Quality Software",
        icon: highQualitySoftware,
    },
    {   key:2,
        title: "Cloud & Integrations",
        icon: cloud,
    },
    {    key:3,
        title: "Mobile",
        icon: mobile,
    },
    {   key:4,
        title: "UX/UI",
        icon: UXUI,
    },
    {   key:6,
        title: "Devops",
        icon: devops,
    },
    {   key:7,
        title: "Testing",
        icon: testing,
    },
    {   key:8,
        title: "Architecture",
        icon: architecture,
    },
]

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <img
        src={arrowRight}
        alt="next arrow"
        style={{ ...style, display: "block"}}
        onClick={onClick}
        className={className}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <img
        src={arrowLeft}
        alt="prev arrow"
        style={{ ...style, display: "block" }}
        onClick={onClick}
        className={className}
      />
    );
  }
const ExpertiseSlider = () => {
    const sliderContainer = {
        width: "100%",
        height: "250px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        justifyContent: "space-evenly",
        alignItems: "center",
        background:"#fff",
        padding: "60px 0px",
    }
    const sectionTitle = {
        fontWeight: "800",
        fontFamily: "Lato",
        width: "100%",
        fontSize: "36px",
        color: "#000",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "50px",
    }
    
    const itemContent = {
        width: "150px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        fontWeight: "100",
        fontFamily: "Lato",
        fontSize: "9px",
    }
    const iconContainer = {
        width: "90px",
        height: "90px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor:"#2c1e49",
        border: "1px solid white",
        borderRadius: "50%",
        marginBottom: "10px"
    }
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        pauseOnHover: false,
        arrows: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 5,
                slidesToScroll: 5,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            }
          ]
    }
    return(
        <div style={sliderContainer}>
            <h2 style={sectionTitle}>Our &nbsp;<span style={{color: "#F7942E"}}>Expertise</span></h2>
            <div style={{width: "80%"}}>
                <SliderContainer settings={settings}>
                    {expertiseList.map((item)=> 
                        <div style={itemContent} key={item.key}>
                            <div style={{width:"100%", display: "flex", justifyContent: "center"}}>
                                <div style={iconContainer}>
                                    <img src={item.icon} style={{width: "50%" , height: "50%"}}/>
                                </div>
                            </div>
                            <div style={{width:"100%", display: "flex", justifyContent: "center"}}>
                                <h1 style={{fontSize: "14px", color:"#2B1D47", fontWeight: "500", textAlign: "center", width:"70%"}}>{item.title}</h1>
                            </div>
                        </div>
                    )}
                </SliderContainer>
            </div>
        </div>
    )
}

export default ExpertiseSlider