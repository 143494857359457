import React from "react"
import { Container, Section, SectionTitle } from "../styles/common"
import Coworking from "../images/Coworking.png"
import TeamGoal from "../images/Teamgoals.png"

const Ideology = () => {
  const activeTab = {
    textAlign: "left",
    margin: "0 0 20px",
    fontWeight: "900",
    fontFamily: "Lato",
    width: "100%",
    fontSize: "22px",
    color: "#2b1d48",
    // textTransform: "uppercase",
    // borderBottom: "6px solid",
    // borderColor: "rgba(164, 95, 167, 0.5)",
    // paddingBottom: "4px",
    transition: "all .2s ease-in",
    // width: "50%",
  }
  const inActiveTab = {
    cursor: "pointer",
    textAlign: "left",
    margin: "0 0 20px",
    fontWeight: "900",
    fontFamily: "Lato",
    width: "100%",
    fontSize: "22px",
    color: "#a45fa7",
    // textTransform: "uppercase",
    opacity: "0.5",
    // borderBottom: "6px solid white",
    // paddingBottom: "4px",
    transition: "all .2s ease-out",
    // width: "50%",
  }
  const borderTitle = {
    width: "140px",
    height: "8px",
    backgroundColor: "#a45fa7",
    display: "block",
    marginTop: "8px",

  }
  return (
    <>
      <Section bgcolor="#fff">
        <Container>
        <SectionTitle
            // transform="uppercase"
            space="30px 0 20px"
            lightChild
            color="black"
          >
            Our <div>ideology</div>
          </SectionTitle>
          <div
            className="TwoGridC"
          >
            <div
              style={{
                width: "80%",
                margin: "0 auto",
              }}
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  justifyItems: "end",
                  alignItems: "end",
                }}
              >
                <h3
                  style={activeTab}
                >
                  Our vision
                  <span style={borderTitle}></span>
                </h3>
              </div>
                <ul style={{display: "flex", flexDirection: "column", rowGap: "8px", padding: "0px 0px 0px 15px"}}>
                  <li>Best digital delivery and accelerator in Africa</li>
                  <li>Best place to work in for African Digital talents</li>
                  <li>Create or help creating an African unicorn</li>
                  <li>Creating a striving African developers community</li>
                </ul>
            </div>
            <div className="reverseCol">
              <img
                src={TeamGoal}
                alt={TeamGoal}
                style={{ width: "90%", margin: "0 auto", display: "block" }}
              />
            </div>
            <div>
              <img
                src={Coworking}
                alt={Coworking}
                style={{ width: "90%", margin: "0 auto", display: "block" }}
              />
            </div>
            <div
              style={{
                width: "80%",
                margin: "0 auto",
              }}
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  justifyItems: "end",
                  alignItems: "end",
                }}
              >
                <h3
                  style={activeTab}
                >
                  Our purpose
                  <span style={borderTitle}></span>
                </h3>
              </div>
              <p style={{lineHeight: "26px"}}>
                  Unleash African digital talents potential for infinite
                  possibilities
                </p>
            </div>
          </div>
        </Container>
      </Section>
    </>
  )
}

export default Ideology
