import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import {
  Values,
  ValuesItem,
  ValueDescription,
  ValueTitle,
  ValueIcon,
  ValuesItemContent,
  IconContainer,
} from "../styles/home"

import { SectionTitle } from "../styles/common"
// import empowerment from "../images/svg/punch.svg"
// import car2 from "../images/svg/car2.svg"
// import passion from "../images/svg/passion.svg"
// import idea from "../images/svg/idea.svg"
// import creativity from "../images/svg/think.svg"
// import respect from "../images/svg/respect.svg"
// import integrity from "../images/svg/integrity.svg"
// import Value from "./Value"
// import valuesData from "../api/ourValues.json"
import weDareToWin from "../images/values/weDareToWin ....svg"
import peopleFirstOrga from "../images/values/peopleFirstOrga.svg"
import purposeAndGoalDrivenCompany from "../images/values/purposeAndGoalDrivenCompany.svg"
import weAdaptAdjustAndRevolve from "../images/values/weAdaptAdjustAndRevolve.svg"
import goFarTogether from "../images/values/goFarTogether.svg"
import weWalkTheTalk from "../images/values/weWalkTheTalk.svg"
import weArePragmaticProbSolver from "../images/values/weArePragmaticProbSolver.svg"
import weLeadFrom from "../images/values/weLeadFrom.svg"

const OurValues = () => (
  // <>
  //   <SectionTitle
  //     transform="uppercase"
  //     space="30px 0 20px"
  //     lightChild
  //     color="black"
  //   >
  //     Our <div>Values</div>
  //   </SectionTitle>
  //   <div style={{
  //     display: "grid",
  //     gridTemplateColumns: "1fr 1fr 1fr",
  //     columnGap: "50px",
  //     justifyItems: "center",
  //     alignItems: "start",
  //   }}>
  //     {valuesData.map((value, index) => {
  //       return <Value key={index} data={value} />
  //     })}
  //   </div>
  // </>

  <div style={{paddingBottom: "60px"}}>
    <Values>
      <SectionTitle
        space="30px 0 60px"
        lightChild
        color="black"
      >
        Our <div>values</div>
      </SectionTitle>
      <ValuesItem>
        <ScrollAnimation animateIn="slideInUp" animateOnce={true}>
          <ValuesItemContent>
            <IconContainer>
              <ValueIcon src={weDareToWin} />
            </IconContainer>
            <ValueTitle>
              We dare to win and exceed customer expectations
            </ValueTitle>
            {/*<ValueDescription>*/}
            {/*  Personal empowerment, responsibility, accountability and shared risk*/}
            {/*  taking are key aspects of our way of doing things.*/}
            {/*</ValueDescription>*/}
          </ValuesItemContent>
        </ScrollAnimation>
      </ValuesItem>
      <ValuesItem>
        <ScrollAnimation animateIn="slideInUp" animateOnce={true}>
          <ValuesItemContent>
            <IconContainer>
              <ValueIcon src={peopleFirstOrga} />
            </IconContainer>
            <ValueTitle>We live in a people-first organization</ValueTitle>
            {/*<ValueDescription>*/}
            {/*  We do not believe in the impossible. We always try seeing problems*/}
            {/*  from different perspectives and look for different ways to solve them*/}
            {/*</ValueDescription>*/}
          </ValuesItemContent>
        </ScrollAnimation>
      </ValuesItem>
      <ValuesItem>
        <ScrollAnimation animateIn="slideInUp" animateOnce={true}>
          <ValuesItemContent>
            <IconContainer>
              <ValueIcon src={purposeAndGoalDrivenCompany} />
            </IconContainer>
            <ValueTitle>We are a purpose and goal driven company</ValueTitle>
            {/*<ValueDescription>*/}
            {/*  We live to code not code to live.*/}
            {/*  <br />*/}
            {/*  We are true believers that the only way to do great work is to love*/}
            {/*  what we do*/}
            {/*</ValueDescription>*/}
          </ValuesItemContent>
        </ScrollAnimation>
      </ValuesItem>
      <ValuesItem>
        <ScrollAnimation animateIn="slideInUp" animateOnce={true}>
          <ValuesItemContent>
            <IconContainer>
              <ValueIcon src={weAdaptAdjustAndRevolve} />
            </IconContainer>
            <ValueTitle>We adapt, adjust and evolve</ValueTitle>
            {/*<ValueDescription>*/}
            {/*  We have positive feeling and high regard about our colleagues,*/}
            {/*  customers and partners and we treat them the way we would like to be*/}
            {/*  treated.*/}
            {/*</ValueDescription>*/}
          </ValuesItemContent>
        </ScrollAnimation>
      </ValuesItem>
      <ValuesItem>
        <ScrollAnimation animateIn="slideInUp" animateOnce={true}>
          <ValuesItemContent>
            <IconContainer>
              <ValueIcon src={goFarTogether} />
            </IconContainer>
            <ValueTitle>We want to go far together</ValueTitle>
            {/*<ValueDescription>*/}
            {/*  We do what we preach and walk the talk.*/}
            {/*  <br />*/}
            {/*  Our actions are consistent and principles-driven*/}
            {/*</ValueDescription>*/}
          </ValuesItemContent>
        </ScrollAnimation>
      </ValuesItem>
      <ValuesItem>
        <ScrollAnimation animateIn="slideInUp" animateOnce={true}>
          <ValuesItemContent>
            <IconContainer>
              <ValueIcon src={weWalkTheTalk} />
            </IconContainer>
            <ValueTitle>We walk the talk</ValueTitle>
            {/*<ValueDescription>*/}
            {/*  We do what we preach and walk the talk.*/}
            {/*  <br />*/}
            {/*  Our actions are consistent and principles-driven*/}
            {/*</ValueDescription>*/}
          </ValuesItemContent>
        </ScrollAnimation>
      </ValuesItem>
      <ValuesItem>
        <ScrollAnimation animateIn="slideInUp" animateOnce={true}>
          <ValuesItemContent>
            <IconContainer>
              <ValueIcon src={weArePragmaticProbSolver} />
            </IconContainer>
            <ValueTitle>We are pragmatic problem solvers</ValueTitle>
            {/*<ValueDescription>*/}
            {/*  We do what we preach and walk the talk.*/}
            {/*  <br />*/}
            {/*  Our actions are consistent and principles-driven*/}
            {/*</ValueDescription>*/}
          </ValuesItemContent>
        </ScrollAnimation>
      </ValuesItem>
      <ValuesItem>
        <ScrollAnimation animateIn="slideInUp" animateOnce={true}>
          <ValuesItemContent>
            <IconContainer>
              <ValueIcon src={weLeadFrom} />
            </IconContainer>
            <ValueTitle>
              We lead from a position of trust and transparency
            </ValueTitle>
            {/*<ValueDescription>*/}
            {/*  We do what we preach and walk the talk.*/}
            {/*  <br />*/}
            {/*  Our actions are consistent and principles-driven*/}
            {/*</ValueDescription>*/}
          </ValuesItemContent>
        </ScrollAnimation>
      </ValuesItem>
    </Values>
  </div>
)

export default OurValues
